import { useState, useEffect, useRef } from "react";

export const useForm = (initialState, callback) => {
  const [inputs, setInputs] = useState(initialState);

  const handleSubmit = event => {
    if (event) event.preventDefault();
    callback();
  };

  const handleInputChange = event => {
    event.persist();

    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    updateField(event.target.name, value);
  };

  const updateField = (name, value) => {
    setInputs({ ...inputs, [name]: value });
  };

  return {
    handleSubmit,
    inputs,
    handleInputChange,
    updateField
  };
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
