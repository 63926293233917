import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { toast } from "react-toastify";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { useForm } from "../hooks";

function UserProfile(props) {
  const { user, onUpdate } = props;
  const [submitting, setSubmitting] = useState(false);

  const updateProfile = () => {
    setSubmitting(true);

    axios
      .patch(`${process.env.REACT_APP_API_HOST}/api/profile`, inputs, {
        withCredentials: true,
      })
      .then(
        (res) => {
          if (res.status === 200) {
            toast("Your profile has been updated!", {
              type: toast.TYPE.SUCCESS,
            });
            if (onUpdate) onUpdate();
          } else {
            toast("Something went wrong while updating your profile!", {
              type: toast.TYPE.ERROR,
            });
          }
        },
        (err) => {
          toast(err.response.data, {
            type: toast.TYPE.ERROR,
          });
          console.error(err);
        }
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialState = {
    displayName: user.displayName,
    "contact.discordUsername": user.contact.discordUsername,
    "contact.twitter": user.contact.twitter,
    bio: user.bio,
    pronouns: user.pronouns,
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(
    initialState,
    updateProfile
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formGroupDisplayName">
        <Form.Label>Display Name</Form.Label>
        <Form.Control
          type="text"
          name="displayName"
          placeholder="Desired display name"
          value={inputs.displayName}
          onChange={handleInputChange}
          maxLength={64}
        />
      </Form.Group>
      <Form.Group controlId="formGroupDiscord">
        <Form.Label>Discord</Form.Label>
        <Form.Control
          type="text"
          name="contact.discordUsername"
          placeholder="Discord Username"
          value={inputs["contact.discordUsername"]}
          onChange={handleInputChange}
          maxLength={37}
        />
      </Form.Group>
      <Form.Group controlId="formGroupTwitter">
        <Form.Label>Twitter</Form.Label>
        <Form.Control
          type="text"
          name="contact.twitter"
          placeholder="Twitter"
          value={inputs["contact.twitter"]}
          onChange={handleInputChange}
          maxLength={15}
        />
      </Form.Group>
      <Form.Group controlId="formGroupBio">
        <Form.Label>Bio</Form.Label>
        <Form.Control
          as="textarea"
          name="bio"
          placeholder="This bio is only visible to admins"
          value={inputs.bio}
          onChange={handleInputChange}
          maxLength={200}
        />
      </Form.Group>
      <Form.Group controlId="formGroupPronouns">
        <Form.Label>Pronouns</Form.Label>
        <Form.Control
          type="text"
          name="pronouns"
          placeholder="Preferred pronouns"
          value={inputs.pronouns || ""}
          onChange={handleInputChange}
          maxLength={20}
        />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={submitting}>
        {submitting && <Spinner as="span" animation="border" size="sm" />} Save
        Changes
      </Button>
    </Form>
  );
}

UserProfile.propTypes = {
  user: PropTypes.shape({
    contact: PropTypes.shape({
      discordUsername: PropTypes.string,
      twitter: PropTypes.string,
    }),
    bio: PropTypes.string,
    pronouns: PropTypes.string,
  }).isRequired,
};

export default UserProfile;
