import React, { useState } from "react";
import { useForm } from "../../hooks";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

function UserForm(props) {
  const { onUpdate } = props;
  const initialState = {
    displayName: "",
    twitchLogin: ""
  };

  const [submitting, setSubmitting] = useState(false);

  const createUser = () => {
    setSubmitting(true);

    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/users`, inputs, {
        withCredentials: true
      })
      .then(
        res => {
          if (res.status === 201) {
            toast("User created!", {
              type: toast.TYPE.SUCCESS
            });
            if (onUpdate) onUpdate();
          } else {
            toast("Something went wrong while creating the user!", {
              type: toast.TYPE.ERROR
            });
          }
        },
        err => {
          toast(err.response.data, {
            type: toast.TYPE.ERROR
          });
          console.error(err);
        }
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  const { inputs, handleInputChange, handleSubmit, updateField } = useForm(
    initialState,
    createUser
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formGroupStart">
        <Form.Label>Twitch Username</Form.Label>
        <Form.Control
          type="text"
          name="twitchLogin"
          placeholder="Twitch Username"
          value={inputs.twitchLogin}
          onChange={handleInputChange}
          onBlur={e => {
            if (inputs.displayName.length === 0) {
              updateField("displayName", e.target.value);
            }
          }}
        />
      </Form.Group>
      <Form.Group controlId="formGroupTitle">
        <Form.Label>Display Name</Form.Label>
        <Form.Control
          type="text"
          name="displayName"
          placeholder="Display Name"
          value={inputs.displayName}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={submitting}>
        {submitting && <Spinner as="span" animation="border" size="sm" />}{" "}
        Create User
      </Button>
    </Form>
  );
}

export default UserForm;
