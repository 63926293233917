import React, { useState } from "react";
import { Link } from "react-router-dom";
import consts from "shared-constants";

import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import { formatNumber } from "../../utils/Utils";

import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";

export default function UsersList(props) {
  const { users } = props;
  const [showBannedUsers, setBannedUsersVis] = useState(false);

  // sort users alphabetically
  users.sort((a, b) =>
    a.displayName.toUpperCase() > b.displayName.toUpperCase() ? 1 : -1
  );

  return (
    <>
      {users.length > 0 ? (
        <Table responsive striped bordered hover size="sm">
          <thead className="text-center">
            <tr>
              <th colSpan={4}>
                User{" "}
                <Button
                  variant="secondary"
                  size="sm"
                  className="float-left"
                  onClick={() => setBannedUsersVis(!showBannedUsers)}
                >
                  {showBannedUsers ? "Hide" : "Show"} Banned
                </Button>
              </th>
              <th colSpan={2}>Twitch Stats</th>
              <th colSpan={3}>Applications</th>
            </tr>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Type</th>
              <th>Followers</th>
              <th>Views</th>
              <th>Pending</th>
              <th>Approved</th>
              <th>Rejected</th>
            </tr>
          </thead>
          <tbody>
            {users.map((u) => (
              <tr
                key={u._id}
                className={
                  showBannedUsers
                    ? u.banned
                      ? "d-table-row"
                      : "d-none"
                    : u.banned
                    ? "d-none"
                    : "d-table-row"
                }
              >
                <td className="text-center">
                  {u.contact.twitter && (
                    <a
                      href={"https://twitter.com/" + u.contact.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TwitterIcon width={16} height={16} />
                    </a>
                  )}
                </td>
                <td className="text-center">
                  <Link to={"/admin/users/" + u._id}>{u.displayName}</Link>
                </td>
                <td className="text-center">
                  {u.isAdmin && (
                    <Badge variant="danger" className="text-uppercase">
                      admin
                    </Badge>
                  )}
                  <Badge className="bg-twitch-purple text-uppercase">
                    {u.twitch.broadcaster_type}
                  </Badge>
                </td>
                <td className="text-right">
                  {formatNumber(u.twitch.follower_count)}
                </td>
                <td className="text-right">
                  {formatNumber(u.twitch.view_count)}
                </td>
                <td className="text-warning text-center">
                  {
                    u.apps.filter(
                      (a) => a.status === consts.APPLICATION_STATUS_PENDING
                    ).length
                  }
                </td>
                <td className="text-success text-center">
                  {
                    u.apps.filter(
                      (a) => a.status === consts.APPLICATION_STATUS_APPROVED
                    ).length
                  }
                </td>
                <td className="text-danger text-center">
                  {
                    u.apps.filter(
                      (a) => a.status === consts.APPLICATION_STATUS_REJECTED
                    ).length
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Alert variant="info">No Users</Alert>
      )}
    </>
  );
}
