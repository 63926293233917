import React from "react";
import { Link } from "react-router-dom";
import consts from "shared-constants";
import moment from "moment";

import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import { formatNumber } from "../../utils/Utils";

import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";

export default function PendingApplications(props) {
  const { apps, events, handlers } = props;
  const { approveClick, rejectClick } = handlers;

  // wait until we have event data
  if (events.length === 0) return null;

  let groupedApps = {};
  apps.forEach((app) => {
    let event = events.find((e) => e._id === app.event._id);
    if (groupedApps.hasOwnProperty(app.event._id)) {
      groupedApps[app.event._id].apps.push(app);
    } else {
      groupedApps[app.event._id] = {
        event: app.event,
        apps: [app],
        approvedCount: event.approvedCount,
      };
    }
  });

  return (
    <>
      {apps.length > 0 ? (
        <Accordion defaultActiveKey={Object.keys(groupedApps)[0]}>
          {Object.keys(groupedApps).map((eventId) => (
            <Card key={eventId}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={eventId}
                className="hover-pointer"
              >
                <Badge variant="danger" className="mr-1">
                  {groupedApps[eventId].apps.length}
                </Badge>
                <strong>
                  {moment(groupedApps[eventId].event.start).format(
                    "MMMM Do, YYYY"
                  )}{" "}
                  <span className="text-muted">
                    {moment(groupedApps[eventId].event.start).fromNow()}
                  </span>
                </strong>{" "}
                <em>
                  ({groupedApps[eventId].approvedCount}/
                  {consts.MAX_APPROVED_GUESTS_PER_EVENT} filled)
                </em>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={eventId}>
                <Card.Body>
                  <Link to={"/admin/events/" + eventId} className="float-right">
                    View Event Page
                  </Link>
                  <Table responsive striped bordered hover size="sm">
                    <thead className="text-center">
                      <tr>
                        <th colSpan={2}>User</th>
                        <th>Partner</th>
                        {/* <th className="d-none d-lg-table-cell">Type</th> */}
                        <th className="d-none d-sm-table-cell">Followers</th>
                        {/* <th className="d-none d-md-table-cell">Views</th> */}
                        <th colSpan={2} className="d-none d-sm-table-cell">
                          Past Applications
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedApps[eventId].apps.map((a) => (
                        <tr key={a._id}>
                          <td className="text-center">
                            {a.user.contact.twitter && (
                              <a
                                href={
                                  "https://twitter.com/" +
                                  a.user.contact.twitter
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <TwitterIcon width={16} height={16} />
                              </a>
                            )}
                          </td>
                          <td className="text-center">
                            <Link to={"/admin/users/" + a.user._id}>
                              {a.user.displayName}
                            </Link>
                          </td>
                          <td className="text-center">
                            {a.teammateUsername ? (
                              <a
                                href={"https://twitch.tv/" + a.teammateUsername}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <em>{a.teammateUsername}</em>
                              </a>
                            ) : (
                              "-"
                            )}
                          </td>
                          {/* <td className="text-center d-none d-lg-table-cell">
                          <Badge className="bg-twitch-purple text-uppercase">
                            {a.user.twitch.broadcaster_type}
                          </Badge>
                        </td> */}
                          <td className="text-right d-none d-sm-table-cell">
                            {formatNumber(a.user.twitch.follower_count)}
                          </td>
                          {/* <td className="text-right d-none d-md-table-cell">
                          {formatNumber(a.user.twitch.view_count)}
                        </td> */}
                          <td className="text-success text-center d-none d-sm-table-cell">
                            {
                              a.user.apps.filter(
                                (a) =>
                                  a.status ===
                                  consts.APPLICATION_STATUS_APPROVED
                              ).length
                            }
                          </td>
                          <td className="text-danger text-center d-none d-sm-table-cell">
                            {
                              a.user.apps.filter(
                                (a) =>
                                  a.status ===
                                  consts.APPLICATION_STATUS_REJECTED
                              ).length
                            }
                          </td>
                          <td className="text-center">
                            <ButtonGroup aria-label="Application Actions">
                              <Button
                                variant="outline-success"
                                size="sm"
                                onClick={() => {
                                  approveClick(a._id);
                                }}
                              >
                                <span role="img" aria-label="checkmark">
                                  ✔
                                </span>{" "}
                                Approve
                              </Button>{" "}
                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={() => {
                                  rejectClick(a._id);
                                }}
                              >
                                <span role="img" aria-label="x">
                                  ❌
                                </span>{" "}
                                Reject
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      ) : (
        <Alert variant="info">No Pending Applications</Alert>
      )}
    </>
  );
}
