import React, { useState } from "react";
import { useInterval } from "../hooks";

export default function CurrentTime(props) {
  const [now, setNow] = useState(new Date());

  useInterval(() => {
    setNow(new Date());
  }, 1000);

  return (
    <p className="pb-0 mb-0" {...props}>
      <small>
        It is currently <strong>{now.toLocaleTimeString()}</strong> on{" "}
        <strong>{now.toLocaleDateString()}</strong>.
      </small>
      <br />
      <small>
        All dates and times are shown in your local timezone (
        {(now.getTimezoneOffset() / 60) * -1} GMT).
      </small>
    </p>
  );
}
