import React, { useState } from "react";
import axios from "axios";
import consts from "shared-constants";
import moment from "moment";
import { toast } from "react-toastify";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import AppStatusBadge from "./AppStatusBadge";

const appStatusToVariant = {
  [consts.APPLICATION_STATUS_PENDING]: "info",
  [consts.APPLICATION_STATUS_APPROVED]: "success",
  [consts.APPLICATION_STATUS_REJECTED]: "danger",
};

function AppList(props) {
  const [apps, setApps] = useState(props.apps || []);
  const [showPastEvents, setPastEventsVis] = useState(false);
  const [showApprovedCancelWarning, setApprovedCancelWarningVis] = useState(
    false
  );

  const handleCancelClick = (appId, status) => {
    if (window.confirm("Are you sure you want to cancel this application?")) {
      if (
        status === consts.APPLICATION_STATUS_APPROVED &&
        !window.confirm(
          "You have been approved for this event already! Are you sure you want to cancel?"
        )
      ) {
        return;
      }

      axios
        .delete(`${process.env.REACT_APP_API_HOST}/api/apps/${appId}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.status === 200) {
            toast("Application canceled!", {
              type: toast.TYPE.SUCCESS,
            });

            // remove the app with this ID
            setApps(apps.filter((a) => a._id !== appId));

            // show warning if an approved app was canceled
            if (status === consts.APPLICATION_STATUS_APPROVED) {
              setApprovedCancelWarningVis(true);
            }
          } else {
            toast("Something went wrong while canceling this application!", {
              type: toast.TYPE.ERROR,
            });
          }
        })
        .catch((err) => {
          toast(err.response.data, {
            type: toast.TYPE.ERROR,
          });
          console.error(err);
        });
    }
  };

  return (
    <Row>
      <Col xs={8}>
        {showApprovedCancelWarning && (
          <Alert variant="danger">
            You have canceled an approved application! Please contact Smight via{" "}
            <a
              href="https://discord.gg/smight"
              target="_blank"
              rel="noopener noreferrer"
            >
              Discord
            </a>{" "}
            or{" "}
            <a
              href="https://twitter.com/smightlp"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
            .
          </Alert>
        )}
        <ListGroup>
          {apps.length > 0 ? (
            apps.map((app) => (
              <ListGroup.Item
                variant={appStatusToVariant[app.status]}
                key={app._id}
                className={
                  moment().isAfter(moment(app.event.start)) && !showPastEvents
                    ? "d-none"
                    : ""
                }
              >
                <strong>
                  {moment(app.event.start).format("MMMM Do, YYYY [at] h:mm a")}
                </strong>{" "}
                {!moment().isAfter(moment(app.event.start)) && (
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => {
                      handleCancelClick(app._id, app.status);
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <AppStatusBadge status={app.status} className="float-right" />
                {app.teammateUsername && (
                  <p>
                    Teammate: <strong>{app.teammateUsername}</strong>
                  </p>
                )}
              </ListGroup.Item>
            ))
          ) : (
            <Alert variant="info">No applications found for this user.</Alert>
          )}
        </ListGroup>
      </Col>
      <Col xs={4}>
        <Button
          variant="secondary"
          size="sm"
          className="mb-3"
          onClick={() => setPastEventsVis(!showPastEvents)}
        >
          {showPastEvents ? "Hide" : "Show"} Past Events
        </Button>
      </Col>
    </Row>
  );
}

export default AppList;
