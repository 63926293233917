import React, { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import consts from "shared-constants";
import { toast } from "react-toastify";
// react-bootstrap
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// native components
import PageModal from "../PageModal";
import PendingApplicationsList from "./PendingApplicationsList";
import UsersPicker from "./UsersPicker";
import UserCard from "./UserCard";

function EventPage(props) {
  const { loggedInUser } = props;
  let { id } = useParams();
  const [event, setEvent] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [showPageModal, setPageModalVisibility] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const { users, handlers } = props;
  const { approveClick, rejectClick } = handlers;

  const fetchData = async () => {
    setLoading(true);

    const result = await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/events/${id}/details`,
      {
        withCredentials: true,
      }
    );

    setLoading(false);

    if (result.status === 200) {
      result.data.startMoment = moment(result.data.start);
      setEvent(result.data);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  const cancelEvent = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_HOST}/api/events/${id}`,
        { canceled: true },
        {
          withCredentials: true,
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast("The event has been canceled!", {
              type: toast.TYPE.SUCCESS,
            });
            setEvent({ ...event, canceled: true });
            //if (onUpdate) onUpdate();
          } else {
            toast("Something went wrong while canceling the event!", {
              type: toast.TYPE.ERROR,
            });
          }
        },
        (err) => {
          toast(err.response.data, {
            type: toast.TYPE.ERROR,
          });
          console.error(err);
        }
      )
      .finally(() => {});
  };

  const deleteEvent = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_HOST}/api/events/${id}`,
        { deleted: true },
        {
          withCredentials: true,
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast("The event has been deleted!", {
              type: toast.TYPE.SUCCESS,
            });
            setEvent({ ...event, deleted: true });
          } else {
            toast("Something went wrong while deleting the event!", {
              type: toast.TYPE.ERROR,
            });
          }
        },
        (err) => {
          toast(err.response.data, {
            type: toast.TYPE.ERROR,
          });
          console.error(err);
        }
      )
      .finally(() => {});
  };

  const handleCancelEvent = () => {
    if (window.confirm("Are you sure you want to cancel this event?")) {
      cancelEvent();
    }
  };

  const handleDeleteEvent = () => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      deleteEvent();
    }
  };

  const addUserToEvent = (user) => {
    if (
      window.confirm(
        `Are you sure you want to add ${user.displayName} this event?`
      )
    ) {
      axios
        .post(
          `${process.env.REACT_APP_API_HOST}/api/apps`,
          { userId: user._id, eventId: event._id },
          {
            withCredentials: true,
          }
        )
        .then(
          (res) => {
            if (res.status === 201) {
              toast("User has been added to this event!", {
                type: toast.TYPE.SUCCESS,
              });
              setSelectedUser(null);
              fetchData();
            } else {
              toast(
                "Something went wrong while adding the user to the event!",
                {
                  type: toast.TYPE.ERROR,
                }
              );
            }
          },
          (err) => {
            toast(err.response.data, {
              type: toast.TYPE.ERROR,
            });
            console.error(err);
          }
        )
        .finally(() => {});
    }
  };

  const removeUserFromEvent = (appId, userDisplayName) => {
    if (
      window.confirm(
        `Are you sure you want to remove ${userDisplayName} from this event?`
      )
    ) {
      axios
        .delete(`${process.env.REACT_APP_API_HOST}/api/apps/${appId}`, {
          withCredentials: true,
        })
        .then(
          (res) => {
            if (res.status === 200) {
              toast("User has been removed from this event!", {
                type: toast.TYPE.SUCCESS,
              });
              fetchData();
            } else {
              toast(
                "Something went wrong while removing the user from the event!",
                {
                  type: toast.TYPE.ERROR,
                }
              );
            }
          },
          (err) => {
            toast(err.response.data, {
              type: toast.TYPE.ERROR,
            });
            console.error(err);
          }
        )
        .finally(() => {});
    }
  };

  if (!event) return null;
  if (isLoading)
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );

  if (event.deleted)
    return (
      <Alert variant="danger">
        This event has been deleted. It will not show up on any calendar.
      </Alert>
    );

  const now = moment();
  const approvedApps = event.apps.filter(
    (a) => a.status === consts.APPLICATION_STATUS_APPROVED
  );
  const pendingApps = event.apps.filter(
    (a) => a.status === consts.APPLICATION_STATUS_PENDING
  );
  const rejectedApps = event.apps.filter(
    (a) => a.status === consts.APPLICATION_STATUS_REJECTED
  );

  return (
    <Container>
      <PageModal
        show={showPageModal}
        onHide={() => {
          setPageModalVisibility(false);
        }}
        page={currentPage}
      />
      <Row>
        <Col xs={3}>
          <h1 className="text-info">{event.title}</h1>
          <h3>{event.startMoment.format("MMMM Do, YYYY")}</h3>
          <h6 className="text-muted">{now.to(event.startMoment)}</h6>
          <hr />
          <ButtonGroup>
            {!event.canceled && event.startMoment.isAfter(now) && (
              <Button
                variant="outline-warning"
                size="sm"
                onClick={handleCancelEvent}
              >
                Cancel Event
              </Button>
            )}
            <Button
              variant="outline-danger"
              size="sm"
              onClick={handleDeleteEvent}
            >
              Delete Event
            </Button>
          </ButtonGroup>
        </Col>
        <Col xs={9}>
          {event.canceled && (
            <Alert variant="warning">This event has been canceled!</Alert>
          )}
          {event.apps.length > 0 ? (
            <Tabs defaultActiveKey="approved" id="app-tabs">
              <Tab
                eventKey="approved"
                title={"Approved (" + approvedApps.length + ")"}
                className="p-3"
              >
                <CardGroup>
                  {approvedApps.map((app) => {
                    let appUser = event.users.find((u) => u._id === app.userId);
                    return (
                      <UserCard user={appUser} style={{ maxWidth: "33%" }}>
                        {app.teammateUsername && (
                          <p className="text-center">
                            Teammate:{" "}
                            <strong className="text-info">
                              {app.teammateUsername}
                            </strong>
                          </p>
                        )}
                        <Button
                          variant="outline-danger"
                          onClick={() => {
                            removeUserFromEvent(app._id, appUser.displayName);
                          }}
                        >
                          Remove from Event
                        </Button>
                      </UserCard>
                    );
                  })}
                  {approvedApps.length <
                    consts.MAX_APPROVED_GUESTS_PER_EVENT && (
                    <Card>
                      <Card.Body>
                        <Card.Title>Add User to Event</Card.Title>
                        <UsersPicker
                          users={users.filter(
                            (u) =>
                              !approvedApps.map((a) => a.userId).includes(u._id)
                          )}
                          onUpdate={setSelectedUser}
                        />
                        {selectedUser && (
                          <Button
                            variant="success"
                            size="lg"
                            block
                            onClick={() => {
                              addUserToEvent(selectedUser, event._id);
                            }}
                          >
                            Add {selectedUser.displayName} to Event
                          </Button>
                        )}
                      </Card.Body>
                    </Card>
                  )}
                </CardGroup>
              </Tab>
              <Tab
                eventKey="pending"
                title={"Pending (" + pendingApps.length + ")"}
                className="p-3"
              >
                {pendingApps.map((app) => {
                  let appUser = event.users.find((u) => u._id === app.userId);
                  return (
                    <PendingApplicationsList
                      app={app}
                      appUser={appUser}
                      loggedInUser={loggedInUser}
                      handlers={{
                        setCurrentPage,
                        setPageModalVisibility,
                        approveClick,
                        rejectClick,
                      }}
                    />
                  );
                })}
              </Tab>
              <Tab
                eventKey="rejected"
                title={"Rejected (" + rejectedApps.length + ")"}
                className="p-3"
              >
                {rejectedApps.map((app) => {
                  let appUser = event.users.find((u) => u._id === app.userId);
                  return (
                    <ListGroup.Item key={app._id}>
                      {appUser.displayName}
                    </ListGroup.Item>
                  );
                })}
              </Tab>
            </Tabs>
          ) : (
            event.appsEnabled && (
              <Alert variant="info">
                No applications found for this event.
              </Alert>
            )
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(EventPage);
