import React from "react";
import moment from "moment";
import consts from "shared-constants";

import Card from "react-bootstrap/Card";

import { ReactComponent as PersonIcon } from "bootstrap-icons/icons/person.svg";
import { ReactComponent as PersonFillIcon } from "bootstrap-icons/icons/person-fill.svg";

export default function EventCard(props) {
  const { event, handlers } = props;
  const { handleEventSelect } = handlers;

  const eventStart = moment(event.start);

  return (
    <Card
      border="secondary"
      className="calendar-card drop-shadow-sm"
      onClick={() => handleEventSelect(event)}
      {...props}
    >
      <Card.Header>
        <h5 className="text-uppercase font-weight-bolder">{event.title}</h5>
      </Card.Header>
      <Card.Body>
        <Card.Title>
          <h4>{eventStart.format("MMMM Do, YYYY")}</h4>
          <span className="text-muted">{eventStart.format("h:mm a")}</span>
        </Card.Title>
        <div
          className="d-flex justify-content-center"
          style={{ fontSize: "2rem" }}
        >
          {event.appsEnabled &&
            Array(consts.MAX_APPROVED_GUESTS_PER_EVENT)
              .fill(<PersonIcon />)
              .fill(
                <PersonFillIcon className="bg-twitch-purple" />,
                0,
                event.approvedCount
              )
              .map((icon, index) => <span key={index}>{icon}</span>)}
        </div>
      </Card.Body>
      <Card.Footer>
        <small>Starts {moment().to(eventStart)}</small>
      </Card.Footer>
    </Card>
  );
}
