import React from "react";
import Card from "react-bootstrap/Card";
import { ReactComponent as PersonIcon } from "bootstrap-icons/icons/person.svg";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";

export default function UserCard(props) {
  const { user } = props;
  const bioLengthLimit = 250;
  return (
    <Card key={user._id} {...props}>
      <a
        href={"https://twitch.tv/" + user.twitch.login}
        target="_blank"
        rel="noopener noreferrer"
        className="w-50 mx-auto"
      >
        {user.twitch.profile_image_url ? (
          <Card.Img variant="top" src={user.twitch.profile_image_url} />
        ) : (
          <PersonIcon width={96} height={96} />
        )}
      </a>

      <Card.Body className="text-center">
        <Card.Title>
          <a
            href={"https://twitch.tv/" + user.twitch.login}
            target="_blank"
            rel="noopener noreferrer"
          >
            {user.displayName}
          </a>
          <br />
          {user.contact.twitter && (
            <a
              href={"https://twitter.com/" + user.contact.twitter}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: "5px" }}
            >
              <TwitterIcon width={16} height={16} />
            </a>
          )}
          <small>{user.pronouns.toUpperCase()}</small>
        </Card.Title>
        <Card.Text>
          <small>
            {user.twitch.description &&
              user.twitch.description.substring(0, bioLengthLimit)}
            {user.twitch.description &&
              user.twitch.description.length > bioLengthLimit &&
              "..."}
          </small>
        </Card.Text>
      </Card.Body>
      {props.children}
    </Card>
  );
}
