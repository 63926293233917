// React
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Toasty!
import { ToastContainer, toast } from "react-toastify";
// CSS
import "./index.scss";
// Native Components
import App from "./App";
import Admin from "./Admin";

const app = (
  <Router>
    <ToastContainer
      pauseOnFocusLoss={false}
      position={toast.POSITION.BOTTOM_LEFT}
    />
    <Switch>
      <Route path="/admin">
        <Admin />
      </Route>
      <Route path="/">
        <App />
      </Route>
    </Switch>
  </Router>
);

render(app, document.getElementById("root"));
