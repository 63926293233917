import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import ms from "ms";

import Spinner from "react-bootstrap/Spinner";

import AppList from "./AppList";

function UserApps(props) {
  const { user } = props;

  const [userApps, setUserApps] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchUserApps = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_HOST}/api/apps`, {
        withCredentials: true
      })
      .then(
        res => {
          setUserApps(res.data);
        },
        err => {
          console.error(err);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUserApps();

    const refreshTimerId = setInterval(fetchUserApps, ms("5m"));

    return () => {
      clearInterval(refreshTimerId);
    };
  }, []);

  if (!user) return null;

  if (isLoading) return <Spinner animation="border" />;

  return <AppList apps={userApps} />;
}

UserApps.propTypes = {
  user: PropTypes.object
};

export default UserApps;
