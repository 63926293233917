import React, { useState } from "react";
import { useForm } from "../../hooks";
import consts from "shared-constants";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";

import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { ReactComponent as BanIcon } from "bootstrap-icons/icons/slash-circle.svg";
import { ReactComponent as HammerIcon } from "bootstrap-icons/icons/hammer.svg";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";

import UserAvatar from "../UserAvatar";
import AppStatusBadge from "../AppStatusBadge";

import { formatNumber } from "../../utils/Utils";

const appStatusToVariant = {
  [consts.APPLICATION_STATUS_PENDING]: "info",
  [consts.APPLICATION_STATUS_APPROVED]: "success",
  [consts.APPLICATION_STATUS_REJECTED]: "danger",
};

export default function UserView(props) {
  const { user, loggedInUser, onUpdate } = props;
  const [cleanForm, setCleanForm] = useState({
    displayName: user.displayName,
    discordUsername: user.contact.discordUsername,
    twitter: user.contact.twitter,
    pronouns: user.pronouns || "",
    bio: user.bio,
  });
  const [banned, setBanned] = useState(user.banned);
  const [isAdmin, setIsAdmin] = useState(user.isAdmin);
  const [submitting, setSubmitting] = useState(false);

  const updateUser = () => {
    setSubmitting(true);

    let update = {
      displayName: inputs.displayName,
      contact: {
        discordUsername: inputs.discordUsername,
        twitter: inputs.twitter,
      },
      pronouns: inputs.pronouns,
      bio: inputs.bio,
    };

    axios
      .patch(
        `${process.env.REACT_APP_API_HOST}/api/users/${user._id}`,
        update,
        {
          withCredentials: true,
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast("User updated!", {
              type: toast.TYPE.SUCCESS,
            });
            setCleanForm({ ...inputs });
            onUpdate();
          } else {
            toast("Something went wrong while updating the user!", {
              type: toast.TYPE.ERROR,
            });
          }
        },
        (err) => {
          toast(err.response.data, {
            type: toast.TYPE.ERROR,
          });
          console.error(err);
        }
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(
    cleanForm,
    updateUser
  );

  const formIsDirty = !_.isEqual(cleanForm, inputs);

  const handleBanClick = (state) => {
    if (
      window.confirm(
        `Are sure you want to ${state ? "ban" : "unban"} this user?`
      )
    ) {
      axios
        .patch(
          `${process.env.REACT_APP_API_HOST}/api/users/${user._id}`,
          { banned: state },
          {
            withCredentials: true,
          }
        )
        .then(
          (res) => {
            if (res.status === 200) {
              toast("User updated!", {
                type: toast.TYPE.SUCCESS,
              });
              setBanned(state);
              onUpdate();
            } else {
              toast("User could not be updated!", {
                type: toast.TYPE.ERROR,
              });
            }
          },
          (err) => {
            toast(err.response.data, {
              type: toast.TYPE.ERROR,
            });
            console.error(err);
          }
        );
    }
  };

  const handleAdminClick = async (state) => {
    if (loggedInUser.isAdmin !== consts.FLAG_IS_SUPERADMIN) {
      toast("You do not have permission to do this!", {
        type: toast.TYPE.ERROR,
      });
      return;
    }

    if (
      window.confirm(
        `Are sure you want to ${state ? "admin" : "unadmin"} this user?`
      )
    ) {
      axios
        .patch(
          `${process.env.REACT_APP_API_HOST}/api/users/${user._id}`,
          { isAdmin: state },
          {
            withCredentials: true,
          }
        )
        .then(
          (res) => {
            if (res.status === 200) {
              toast("User updated!", {
                type: toast.TYPE.SUCCESS,
              });
              setIsAdmin(state);
              onUpdate();
            } else {
              toast("User could not be updated!", {
                type: toast.TYPE.ERROR,
              });
            }
          },
          (err) => {
            toast(err.response.data, {
              type: toast.TYPE.ERROR,
            });
            console.error(err);
          }
        );
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={3} className="text-center">
          <UserAvatar imageUrl={user.twitch.profile_image_url} />

          <a
            href={"https://twitch.tv/" + user.twitch.login}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h4>{inputs.displayName}</h4>
          </a>

          {inputs.pronouns && (
            <>
              <span className="text-uppercase text-muted">
                {inputs.pronouns}
              </span>
              <br />
            </>
          )}

          {inputs.twitter && (
            <>
              <a
                href={"https://twitter.com/" + inputs.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon width={16} height={16} />
              </a>
              <br />
            </>
          )}

          {isAdmin && (
            <Badge variant="danger" className="text-uppercase">
              admin
            </Badge>
          )}

          <Badge className="bg-twitch-purple text-uppercase">
            {user.twitch.broadcaster_type}
          </Badge>

          <hr />

          <Row className="text-warning">
            <Col className="text-right">Pending</Col>
            <Col className="text-left font-weight-bold">
              {
                user.apps.filter(
                  (a) => a.status === consts.APPLICATION_STATUS_PENDING
                ).length
              }
            </Col>
          </Row>
          <Row className="text-success">
            <Col className="text-right">Approved</Col>
            <Col className="text-left font-weight-bold">
              {
                user.apps.filter(
                  (a) => a.status === consts.APPLICATION_STATUS_APPROVED
                ).length
              }
            </Col>
          </Row>
          <Row className="text-danger">
            <Col className="text-right">Rejected</Col>
            <Col className="text-left font-weight-bold">
              {
                user.apps.filter(
                  (a) => a.status === consts.APPLICATION_STATUS_REJECTED
                ).length
              }
            </Col>
          </Row>
          <Row>
            <Col className="text-right">Followers</Col>
            <Col className="text-left font-weight-bold">
              {formatNumber(user.twitch.follower_count)}
            </Col>
          </Row>
          <Row>
            <Col className="text-right">Views</Col>
            <Col className="text-left font-weight-bold">
              {formatNumber(user.twitch.view_count)}
            </Col>
          </Row>

          <hr />

          <ButtonGroup>
            {!isAdmin && !banned && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => handleBanClick(true)}
              >
                <BanIcon width={18} height={18} /> Ban User
              </Button>
            )}
            {!isAdmin && banned && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => handleBanClick(false)}
              >
                <BanIcon width={18} height={18} /> UnBan User
              </Button>
            )}

            {loggedInUser.isAdmin === consts.FLAG_IS_SUPERADMIN && !isAdmin && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => handleAdminClick(true)}
              >
                <HammerIcon width={18} height={18} /> Make Admin
              </Button>
            )}
            {loggedInUser.isAdmin === consts.FLAG_IS_SUPERADMIN && isAdmin && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => handleAdminClick(false)}
              >
                <HammerIcon width={18} height={18} /> Remove Admin
              </Button>
            )}
          </ButtonGroup>
        </Col>
        <Col xs={9}>
          {formIsDirty && (
            <Button
              size="sm"
              variant="primary"
              type="submit"
              disabled={submitting}
              className="float-right"
              onClick={handleSubmit}
            >
              {submitting && <Spinner as="span" animation="border" size="sm" />}{" "}
              Update User
            </Button>
          )}
          <Tabs defaultActiveKey="profile" id="edit-user-tabs">
            <Tab eventKey="profile" title="Profile" className="p-3">
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formGroupDisplayName">
                  <Form.Label>Display Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="User's display name"
                    value={inputs.displayName}
                    name="displayName"
                    onChange={handleInputChange}
                    maxLength={64}
                  />
                </Form.Group>
                <Form.Group controlId="formGroupBio">
                  <Form.Label>Bio</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="This bio is only visible to admins"
                    value={inputs.bio}
                    name="bio"
                    onChange={handleInputChange}
                    maxLength={200}
                  />
                </Form.Group>
                <Form.Group controlId="formGroupPronouns">
                  <Form.Label>Pronouns</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Preferred pronouns"
                    value={inputs.pronouns || ""}
                    name="pronouns"
                    onChange={handleInputChange}
                    maxLength={20}
                  />
                </Form.Group>
              </Form>
            </Tab>
            <Tab eventKey="apps" title="Applications" className="p-3">
              <ListGroup>
                {user.apps.length > 0 ? (
                  user.apps.map((app) => (
                    <ListGroup.Item
                      variant={appStatusToVariant[app.status]}
                      key={app._id}
                    >
                      <strong>
                        {moment(
                          user.events.find((e) => e._id === app.eventId).start
                        ).format("MMMM Do, YYYY [at] h:mm a")}
                      </strong>{" "}
                      <AppStatusBadge
                        status={app.status}
                        className="float-right"
                      />
                    </ListGroup.Item>
                  ))
                ) : (
                  <Alert variant="secondary">
                    No applications found for this user.
                  </Alert>
                )}
              </ListGroup>
            </Tab>

            <Tab eventKey="contact" title="Contact" className="p-3">
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formGroupDiscord">
                  <Form.Label>Discord</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Discord Username"
                    name="discordUsername"
                    value={inputs.discordUsername}
                    onChange={handleInputChange}
                    maxLength={37}
                  />
                </Form.Group>
                <Form.Group controlId="formGroupTwitter">
                  <Form.Label>Twitter</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Twitter"
                    name="twitter"
                    value={inputs.twitter}
                    onChange={handleInputChange}
                    maxLength={15}
                  />
                </Form.Group>
              </Form>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}
