// This is here so I can deploy
module.exports = Object.freeze({
  APPLICATION_STATUS_PENDING: "pending",
  APPLICATION_STATUS_REJECTED: "rejected",
  APPLICATION_STATUS_APPROVED: "approved",
  APPLICATION_STATUS_EXPIRED: "expired",
  MAX_APPROVED_GUESTS_PER_EVENT: 4,
  MAX_PENDING_APPLICATIONS_PER_USER: 2,
  FLAG_IS_ADMIN: 1,
  FLAG_IS_SUPERADMIN: 2
});