import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import consts from "shared-constants";
// react-bootstrap
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Jumbotron from "react-bootstrap/Jumbotron";
// native components
import PageModal from "../PageModal";
import EventForm from "./EventForm";
// css
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../styles/rbc/dark.scss";
// assets
import { ReactComponent as PlusIcon } from "bootstrap-icons/icons/plus.svg";

moment.updateLocale("en", {
  week: {
    dow: 1,
    doy: 1
  }
});
const localizer = momentLocalizer(moment);

const calendarScrollToTime = new Date(1970, 1, 1, 12);
const calendarMinTime = new Date(1970, 1, 1, 0);
const calendarMaxTime = new Date(1970, 1, 1, 23);

const EventsCalendar = withRouter(props => {
  const { events, onUpdate } = props;

  const [showNewEventModal, setNewEventModalVis] = useState(false);

  const handleEventSelect = event => {
    props.history.push("/admin/events/" + event._id);
  };

  return (
    <div className="d-flex flex-column">
      <Button
        variant="primary"
        className="align-self-end mb-3"
        size="md"
        onClick={() => setNewEventModalVis(true)}
      >
        <PlusIcon width={24} height={24} /> Create New Event
      </Button>
      <Jumbotron style={{ padding: "1em" }}>
        <Calendar
          localizer={localizer}
          views={["month", "week"]}
          defaultView={"month"}
          events={events}
          style={{ height: 500 }}
          onSelectEvent={handleEventSelect}
          titleAccessor={e => (
            <>
              {e.title}{" "}
              {e.appsEnabled && (
                <>
                  ({e.approvedCount}/{consts.MAX_APPROVED_GUESTS_PER_EVENT})
                  {e.pendingCount > 0 && (
                    <Badge variant="danger" className="float-right">
                      {e.pendingCount}
                    </Badge>
                  )}
                </>
              )}
            </>
          )}
          startAccessor={e => new Date(e.start)}
          endAccessor={e => new Date(e.end)}
          step={120}
          timeslots={1}
          scrollToTime={calendarScrollToTime}
          min={calendarMinTime}
          max={calendarMaxTime}
        />
      </Jumbotron>
      <PageModal
        show={showNewEventModal}
        size="sm"
        onHide={() => setNewEventModalVis(false)}
        page={{
          title: "Create New Event",
          component: (
            <EventForm
              onUpdate={() => {
                setNewEventModalVis(false);
                if (onUpdate) onUpdate();
              }}
            />
          )
        }}
      />
    </div>
  );
});

export default EventsCalendar;
