import React, { useState } from "react";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

export default function UsersPicker(props) {
  const { users, onUpdate } = props;
  const [selectedUser, setSelected] = useState([]);

  if (users.length === 0) return null;

  return (
    <div {...props}>
      <Typeahead
        id="user-picker"
        onChange={(sel) => {
          setSelected(sel);
          onUpdate(sel[0]);
        }}
        options={users}
        labelKey="displayName"
        selected={selectedUser}
        placeholder="Start typing a name..."
      />
    </div>
  );
}
