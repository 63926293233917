import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import ms from "ms";
import consts from "shared-constants";
// React Bootstrap Components
import Alert from "react-bootstrap/Alert";
import Jumbotron from "react-bootstrap/Jumbotron";
import CardDeck from "react-bootstrap/CardDeck";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
// native components
import EventCard from "./EventCard";
// bootstrap-icons
import { ReactComponent as QuestionIcon } from "bootstrap-icons/icons/question-square.svg";

function PublicCalendar(props) {
  const { handlers, user } = props;
  const { handleEventSelect } = handlers;
  const [events, setEvents] = useState([]);
  const [nextEvent, setNextEvent] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [eventsLoaded, setLoaded] = useState(false);

  // Call the API to retrieve event data
  const fetchEvents = async () => {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_API_HOST}/api/events`)
      .then(
        (res) => {
          if (res.status === 200) {
            const upcomingEvents = res.data.filter(
              (e) =>
                moment().isBefore(moment(e.end)) &&
                moment().add(30, "days").isAfter(moment(e.start))
            );
            setEvents(upcomingEvents.slice(1, 21));
            setNextEvent(upcomingEvents.shift());
          }
        },
        (err) => {
          console.error(err);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  useEffect(() => {
    fetchEvents();

    const refreshTimerId = setInterval(fetchEvents, ms("1m"));

    return () => {
      clearInterval(refreshTimerId);
    };
  }, []);

  if (isLoading && !eventsLoaded) return <Spinner animation="border" />;

  if (!isLoading && eventsLoaded && events.length === 0)
    return (
      <Alert variant="info">
        There are no upcoming events. Check back soon!
      </Alert>
    );

  return (
    <>
      {events.length > 0 && nextEvent && (
        <Jumbotron className="text-center">
          {!user && (
            <LinkContainer to="/req">
              <Button variant="info">
                <QuestionIcon width={24} height={24} /> How to Play
              </Button>
            </LinkContainer>
          )}
          {moment().isAfter(moment(nextEvent.start)) &&
          moment().isBefore(moment(nextEvent.end)) ? (
            <h1>
              Now live at{" "}
              <a
                href="https://twitch.tv/smight"
                target="_blank"
                rel="noopener noreferrer"
              >
                twitch.tv/Smight
              </a>
              !
            </h1>
          ) : (
            <h1>
              The next event starts{" "}
              <strong className="text-success">
                {moment(nextEvent.start).fromNow()}
              </strong>
              !
            </h1>
          )}
          <EventCard event={nextEvent} handlers={{ handleEventSelect }} />
          {user &&
            nextEvent.approvedCount < consts.MAX_APPROVED_GUESTS_PER_EVENT && (
              <Button
                variant="success"
                size="lg"
                onClick={() => handleEventSelect(nextEvent)}
                className="mb-3"
              >
                View Details / Apply
              </Button>
            )}
          {(!user ||
            nextEvent.approvedCount >=
              consts.MAX_APPROVED_GUESTS_PER_EVENT) && (
            <Button
              variant="info"
              size="lg"
              onClick={() => handleEventSelect(nextEvent)}
            >
              View Details
            </Button>
          )}
        </Jumbotron>
      )}
      {events.length > 0 && (
        <>
          <h1 className="text-center">Upcoming Events</h1>
          <CardDeck>
            {events.map((e) => (
              <EventCard
                event={e}
                key={e._id}
                handlers={{ handleEventSelect }}
              />
            ))}
          </CardDeck>
        </>
      )}
    </>
  );
}

export default PublicCalendar;
