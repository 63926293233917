import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Spinner from "react-bootstrap/Spinner";

import UserView from "./UserView";

export default function UserPage(props) {
  let { id } = useParams();
  const { loggedInUser, onUpdate } = props;
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const result = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/users/${id}`,
        {
          withCredentials: true,
        }
      );

      setLoading(false);

      if (result.status === 200) {
        setUser(result.data);
      }
    };

    fetchData();
  }, [id]);

  return isLoading || !user ? (
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  ) : (
    <UserView user={user} loggedInUser={loggedInUser} onUpdate={onUpdate} />
  );
}
