import React from "react";

import Image from "react-bootstrap/Image";
import { ReactComponent as PersonIcon } from "bootstrap-icons/icons/person.svg";

export default function UserAvatar(props) {
  const { size, imageUrl, alt } = props;

  let px = 64;
  if (size) {
    switch (size) {
      case "sm":
        px = 16;
        break;
      case "md":
        px = 48;
        break;
      case "lg":
        px = 64;
        break;
      default:
        px = 64;
        break;
    }
  }

  return imageUrl ? (
    <Image
      src={imageUrl}
      roundedCircle
      className="d-inline-block mb-2 user-avatar"
      width={px}
      height={px}
      alt={alt || ""}
    />
  ) : (
    <PersonIcon
      className="d-inline-block mb-2 user-avatar"
      width={px}
      height={px}
    />
  );
}
