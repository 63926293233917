import React from "react";
import axios from "axios";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ListGroup from "react-bootstrap/ListGroup";

import { ReactComponent as PersonIcon } from "bootstrap-icons/icons/person.svg";

import UserView from "./UserView";

export default function PendingApplicationsList(props) {
  const { app, loggedInUser, handlers } = props;
  let appUser = props.appUser;
  const {
    setCurrentPage,
    setPageModalVisibility,
    approveClick,
    rejectClick,
  } = handlers;
  return (
    <ListGroup.Item key={app._id}>
      <Button
        variant="primary"
        size="sm"
        onClick={async () => {
          let result = await axios.get(
            `${process.env.REACT_APP_API_HOST}/api/users/${appUser._id}`,
            {
              withCredentials: true,
            }
          );
          if (result.status === 200) {
            appUser = result.data;
          }
          setCurrentPage({
            title: "User Details",
            component: <UserView user={appUser} loggedInUser={loggedInUser} />,
          });
          setPageModalVisibility(true);
        }}
      >
        <PersonIcon width={18} height={18} /> {appUser.displayName}
      </Button>

      <ButtonGroup aria-label="Application Actions" className="float-right">
        <Button
          variant="outline-success"
          size="sm"
          onClick={() => {
            approveClick(app._id);
          }}
        >
          <span role="img" aria-label="checkmark">
            ✔
          </span>{" "}
          Approve
        </Button>{" "}
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => {
            rejectClick(app._id);
          }}
        >
          <span role="img" aria-label="x">
            ❌
          </span>{" "}
          Reject
        </Button>
      </ButtonGroup>

      {app.teammateUsername && (
        <p>
          Teammate: <strong>{app.teammateUsername}</strong>
        </p>
      )}
    </ListGroup.Item>
  );
}
