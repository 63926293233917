import React, { useState } from "react";
import { useForm } from "../../hooks";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "../../styles/rdt/dark.scss";

function EventForm(props) {
  const { onUpdate } = props;
  const initialState = {
    title: "Arcade Pit",
    start: moment(),
    duration: "1",
    appsEnabled: true,
    canceled: false
  };

  const [submitting, setSubmitting] = useState(false);

  const createEvent = () => {
    setSubmitting(true);

    let newEvent = {
      ...inputs,
      end: inputs.start.clone().add(inputs.duration, "h")
    };

    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/events`, newEvent, {
        withCredentials: true
      })
      .then(
        res => {
          if (res.status === 201) {
            toast("Event created!", {
              type: toast.TYPE.SUCCESS
            });
            if (onUpdate) onUpdate();
          } else {
            toast("Something went wrong while creating the event!", {
              type: toast.TYPE.ERROR
            });
          }
        },
        err => {
          toast(err.response.data, {
            type: toast.TYPE.ERROR
          });
          console.error(err);
        }
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  const { inputs, handleInputChange, handleSubmit, updateField } = useForm(
    initialState,
    createEvent
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formGroupTitle">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          name="title"
          placeholder="Event Title"
          value={inputs.title}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formGroupStart">
        <Form.Label>Start Time</Form.Label>
        <Datetime
          value={inputs.start}
          onChange={newValue => {
            updateField("start", newValue);
          }}
        />
      </Form.Group>
      <Form.Group controlId="formGroupEnd">
        <Form.Label>Event Duration</Form.Label>
        <Form.Row className="font-weight-bold">
          {inputs.duration} Hour{inputs.duration > 1 && "s"}
        </Form.Row>
        <Form.Row>
          <input
            type="range"
            name="duration"
            min="1"
            max="24"
            value={inputs.duration}
            onChange={handleInputChange}
          />
        </Form.Row>
      </Form.Group>
      <Form.Group controlId="formGroupAppsEnabled">
        <Form.Check
          type="switch"
          id="appsEnabled"
          name="appsEnabled"
          label="Enable Applications"
          checked={inputs.appsEnabled}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={submitting}>
        {submitting && <Spinner as="span" animation="border" size="sm" />}{" "}
        Create Event
      </Button>
    </Form>
  );
}

export default EventForm;
