import React from "react";
import { Switch, Route } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import consts from "shared-constants";
// react-bootstrap
import { LinkContainer } from "react-router-bootstrap";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
// Native Components
import CurrentTime from "./components/CurrentTime";
import EventModal from "./components/EventModal";
import PublicCalendar from "./components/PublicCalendar";
import UserBar from "./components/UserBar";
import UserApps from "./components/UserApps";
import UserProfile from "./components/UserProfile";
import HowToPlay from "./components/HowToPlay";
// Assets
import GlitchIcon from "./assets/GlitchIcon_white.png";
// bootstrap-icons
import { ReactComponent as HammerIcon } from "bootstrap-icons/icons/hammer.svg";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      userLoaded: false,
      selectedEvent: null,
      eventModalVisible: false,
      userCanApplyToSelectedEvent: false,
    };
  }

  componentDidMount() {
    // Get initial state
    this.fetchUserData();
  }

  fetchUserData() {
    // Call the API to retrieve info about the currently logged-in user
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/me`, {
        withCredentials: true,
      })
      .then(
        (res) => {
          if (res.status === 200) {
            this.setState({ user: res.data });
          }
        },
        (err) => {
          console.error(err);
        }
      )
      .finally(() => {
        this.setState({ userLoaded: true });
      });
  }

  showEventModal() {
    this.setState({ eventModalVisible: true });
  }

  closeEventModal() {
    this.setState({ eventModalVisible: false });
  }

  handleEventSelect(event, e) {
    // Set the event data to what we have cached locally already
    this.setState({ selectedEvent: event });

    // Show modal with event info
    this.showEventModal();

    // Request event details from API so the event gets refreshed
    axios.get(`${process.env.REACT_APP_API_HOST}/api/events/${event._id}`).then(
      (res) => {
        // user can apply if they're logged-in, not banned, the event hasn't been canceled, it's not in the past, and # of approved guests < max
        // the backend will handle all the other cases
        let userCanApplyToSelectedEvent =
          this.state.user &&
          !this.state.user.banned &&
          !res.data.canceled &&
          res.data.appsEnabled &&
          moment().isBefore(moment(res.data.start)) &&
          res.data.approvedCount < consts.MAX_APPROVED_GUESTS_PER_EVENT;

        this.setState({
          selectedEvent: res.data,
          userCanApplyToSelectedEvent,
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }

  handleAppClick(e) {
    if (window.confirm("Are you sure you want to apply to this event?")) {
      axios
        .post(
          `${process.env.REACT_APP_API_HOST}/api/apps`,
          { eventId: this.state.selectedEvent._id },
          { withCredentials: true }
        )
        .then(
          (res) => {
            if (res.status === 201) {
              toast("Your application has been received!", {
                type: toast.TYPE.SUCCESS,
              });
            } else {
              toast("Something went wrong with your application!", {
                type: toast.TYPE.ERROR,
              });
            }
          },
          (err) => {
            toast(err.response.data, {
              type: toast.TYPE.ERROR,
            });
            console.error(err);
          }
        )
        .finally(() => {
          this.closeEventModal();
        });
    }
  }

  handleTeamAppClick(e) {
    let teammateUsername = window.prompt(
      "Please enter the Twitch username of your teammate."
    );
    if (!teammateUsername) {
      return;
    }

    if (!/^(#)?[a-zA-Z0-9_]{4,25}$/.test(teammateUsername)) {
      toast("This doesn't appear to be a valid Twitch username!", {
        type: toast.TYPE.ERROR,
      });
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_HOST}/api/apps`,
        { eventId: this.state.selectedEvent._id, teammateUsername },
        { withCredentials: true }
      )
      .then(
        (res) => {
          if (res.status === 201) {
            toast("Your application has been received!", {
              type: toast.TYPE.SUCCESS,
            });
          } else {
            toast("Something went wrong with your application!", {
              type: toast.TYPE.ERROR,
            });
          }
        },
        (err) => {
          toast(err.response.data, {
            type: toast.TYPE.ERROR,
          });
          console.error(err);
        }
      )
      .finally(() => {
        this.closeEventModal();
      });
  }

  render() {
    return (
      <Container>
        <header>
          {this.state.user && <UserBar user={this.state.user} />}
          {this.state.userLoaded && !this.state.user && (
            <div className="py-3 text-center">
              <a
                href={process.env.REACT_APP_LOGIN_URL}
                className="btn btn-lg btn-twitch"
              >
                <img src={GlitchIcon} alt="" style={{ width: "2em" }} />
                Log In with Twitch
              </a>
            </div>
          )}
        </header>

        <main style={{ minHeight: "60vh" }}>
          <Switch>
            <Route path="/" exact>
              <PublicCalendar
                handlers={{
                  handleEventSelect: this.handleEventSelect.bind(this),
                }}
                user={this.state.user}
              />
            </Route>
            <Route path="/apps">
              <UserApps user={this.state.user} />
            </Route>
            <Route path="/profile">
              {this.state.userLoaded && this.state.user && (
                <UserProfile
                  user={this.state.user}
                  onUpdate={this.fetchUserData.bind(this)}
                />
              )}
            </Route>
            <Route path="/req">
              <HowToPlay />
            </Route>
          </Switch>
        </main>

        <footer className="text-center mt-3">
          {this.state.user && this.state.user.isAdmin && (
            <LinkContainer to="/admin">
              <Button variant="danger">
                <HammerIcon width={24} height={24} /> Access Admin Panel
              </Button>
            </LinkContainer>
          )}
          <CurrentTime />
        </footer>

        <EventModal
          event={this.state.selectedEvent}
          show={this.state.eventModalVisible}
          handleClose={this.closeEventModal.bind(this)}
          enableApply={this.state.userCanApplyToSelectedEvent}
          handleApply={this.handleAppClick.bind(this)}
          handleTeammateApply={this.handleTeamAppClick.bind(this)}
          user={this.state.user}
        />
      </Container>
    );
  }
}

export default App;
