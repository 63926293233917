import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
// react-bootstrap
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
// react-router-bootstrap
import { LinkContainer } from "react-router-bootstrap";
// Native Components
import UserAvatar from "./UserAvatar";
// bootstrap-icons
import { ReactComponent as CalendarIcon } from "bootstrap-icons/icons/calendar.svg";
import { ReactComponent as PencilIcon } from "bootstrap-icons/icons/pencil.svg";
import { ReactComponent as HammerIcon } from "bootstrap-icons/icons/hammer.svg";
import { ReactComponent as EjectIcon } from "bootstrap-icons/icons/eject.svg";
import { ReactComponent as QuestionIcon } from "bootstrap-icons/icons/question-square.svg";
// Other Assets
import SmightLogo from "../assets/smight-avatar.png";

function UserBar(props) {
  const { user } = props;

  return (
    <Navbar bg="secondary" variant="dark" className="mb-3" expand="lg">
      <LinkContainer to="/" exact>
        <Navbar.Brand href="/">
          <img
            src={SmightLogo}
            alt=""
            className="d-inline-block align-top rounded-circle drop-shadow-sm"
            width={64}
            height={64}
          />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="user-bar-nav" />
      <Navbar.Collapse id="user-bar-nav">
        <Nav
          variant="pills"
          className="mr-auto"
          activeKey={props.location.pathname}
        >
          <LinkContainer to="/" exact>
            <Nav.Link eventKey="/">
              <CalendarIcon width={24} height={24} /> Upcoming Events
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to="/apps" exact>
            <Nav.Link eventKey="/apps">
              <PencilIcon width={24} height={24} />
              My Applications
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to="/req" exact>
            <Nav.Link eventKey="/req">
              <QuestionIcon width={24} height={24} /> How to Play
            </Nav.Link>
          </LinkContainer>
        </Nav>

        <Nav
          className="ml-auto align-items-center"
          activeKey={props.location.pathname}
        >
          <LinkContainer to="/profile" exact>
            <Nav.Link eventKey="/profile">
              My Profile{" "}
              <UserAvatar
                imageUrl={user.twitch.profile_image_url}
                alt={"Logged in as " + user.displayName}
                size="md"
              />
            </Nav.Link>
          </LinkContainer>
          {user.isAdmin && (
            <LinkContainer to="/admin" title="Access Admin Panel">
              <Button variant="secondary" size="md">
                <HammerIcon width={28} height={28} /> Admin
              </Button>
            </LinkContainer>
          )}
          <Button
            href={process.env.REACT_APP_LOGOUT_URL}
            variant="secondary"
            size="md"
          >
            <EjectIcon width={28} height={28} /> Logout
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

UserBar.propTypes = {
  user: PropTypes.shape({ isAdmin: PropTypes.number }).isRequired,
};

export default withRouter(UserBar);
