import React from "react";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import consts from "shared-constants";

import UserAvatar from "./UserAvatar";

function EventModal(props) {
  const { show, handleClose, handleApply, handleTeammateApply, event, enableApply, user } = props;

  if (!event) return null;
  const eventMoment = moment(event.start);
  const now = moment();
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{event.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{eventMoment.format("MMMM Do, YYYY")}</h5>
        <h6 className="text-muted">{now.to(eventMoment)}</h6>
        <hr />
        {event.appsEnabled && (
          <p>
            Approved Guests: {event.approvedCount} /{" "}
            {consts.MAX_APPROVED_GUESTS_PER_EVENT}
          </p>
        )}
        <ul className="d-flex flex-column approved-user-list">
          {event.approvedUsers &&
            event.approvedUsers.map(u => (
              <li key={u.displayName}>
                <a
                  href={"https://twitch.tv/" + u.twitchLogin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <UserAvatar imageUrl={u.profileImageUrl} size="md" />
                </a>
                {"  "}
                <a
                  href={"https://twitch.tv/" + u.twitchLogin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>{u.displayName}</strong>
                </a>
              </li>
            ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        {!user && <Button
            variant="success"
            disabled={!enableApply}
            onClick={handleApply}
          >Log In to Apply!</Button>}
        {event.appsEnabled && user && (
          <>
          <Button
            variant="success"
            disabled={!enableApply}
            onClick={handleApply}
          >Apply Solo</Button>
          <Button
            variant="success"
            disabled={!enableApply}
            onClick={handleTeammateApply}
          >Apply with a Teammate</Button></>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default EventModal;
