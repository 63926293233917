import React from "react";

import Badge from "react-bootstrap/Badge";

import consts from "shared-constants";

export default function AppStatusBadge(props) {
  const { status } = props;

  let variant;

  switch (status) {
    case consts.APPLICATION_STATUS_PENDING:
      variant = "info";
      break;
    case consts.APPLICATION_STATUS_APPROVED:
      variant = "success";
      break;
    case consts.APPLICATION_STATUS_REJECTED:
      variant = "danger";
      break;
    default:
      variant = "secondary";
  }

  return (
    <Badge variant={variant} {...props}>
      {props.status}
    </Badge>
  );
}
