// react
import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
// misc
import axios from "axios";
import ms from "ms";
// react-bootstrap
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
// react-router-bootstrap
import { LinkContainer } from "react-router-bootstrap";
// toast
import { toast } from "react-toastify";
// native components
import CurrentTime from "./components/CurrentTime";
import EventsCalendar from "./components/Admin/EventsCalendar";
import EventPage from "./components/Admin/EventPage";
import NavBar from "./components/Admin/NavBar";
import PageModal from "./components/PageModal";
import PendingApplications from "./components/Admin/PendingApplications";
import UserForm from "./components/Admin/UserForm";
import UserPage from "./components/Admin/UserPage";
import UsersList from "./components/Admin/UsersList";
// assets
import { ReactComponent as CalendarIcon } from "bootstrap-icons/icons/calendar.svg";
import { ReactComponent as PlusIcon } from "bootstrap-icons/icons/plus.svg";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      pendingApps: [],
      allUsers: [],
      allEvents: [],
      showNewUserModal: false,
    };
  }

  componentDidMount() {
    // Get initial state
    this.refreshData();

    // Start a timer to auto-refresh data
    this.refreshTimerId = setInterval(this.refreshData.bind(this), ms("1m"));
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimerId);
  }

  refreshData() {
    this.fetchUserData();
    this.fetchPendingApplications();
    this.fetchAllUsers();
    this.fetchAllEvents();
  }

  fetchUserData() {
    // Call the API to retrieve info about the currently logged-in user
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/me`, {
        withCredentials: true,
      })
      .then(
        (res) => {
          this.setState({ user: res.data });
        },
        (err) => {
          console.error(err);
        }
      );
  }

  fetchPendingApplications() {
    // Call the API to retrieve event data
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/apps/pending`, {
        withCredentials: true,
      })
      .then(
        (res) => {
          this.setState({
            pendingApps: res.data,
          });
        },
        (err) => {
          console.error(err);
        }
      );
  }

  fetchAllUsers() {
    // Call the API to retrieve event data
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/users`, {
        withCredentials: true,
      })
      .then(
        (res) => {
          this.setState({
            allUsers: res.data,
          });
        },
        (err) => {
          console.error(err);
        }
      );
  }

  fetchAllEvents() {
    // Call the API to retrieve event data
    axios.get(`${process.env.REACT_APP_API_HOST}/api/events`).then(
      (res) => {
        this.setState({
          allEvents: res.data,
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }

  handleApproveClick(appId) {
    if (
      window.confirm(
        `Are you sure you want to approve this user for this event?`
      )
    ) {
      axios
        .post(
          `${process.env.REACT_APP_API_HOST}/api/apps/${appId}/approve`,
          {},
          {
            withCredentials: true,
          }
        )
        .then(
          (res) => {
            if (res.status === 200) {
              toast(res.data, {
                type: toast.TYPE.SUCCESS,
              });

              this.refreshData();
            } else {
              toast(res.data, {
                type: toast.TYPE.ERROR,
              });
            }
          },
          (err) => {
            console.error(err);
          }
        );
    }
  }

  handleRejectClick(appId) {
    if (
      window.confirm(
        "Are you sure you want to reject this user for this event?"
      )
    ) {
      axios
        .post(
          `${process.env.REACT_APP_API_HOST}/api/apps/${appId}/reject`,
          {},
          {
            withCredentials: true,
          }
        )
        .then(
          (res) => {
            if (res.status === 200) {
              toast(res.data, {
                type: toast.TYPE.SUCCESS,
              });

              this.refreshData();
            } else {
              toast(res.data, {
                type: toast.TYPE.ERROR,
              });
            }
          },
          (err) => {
            console.error(err);
          }
        );
    }
  }

  render() {
    if (!this.state.user || !this.state.user.isAdmin) {
      return null;
    }

    return (
      <Container>
        <header>
          <NavBar pendingApps={this.state.pendingApps} user={this.state.user} />
        </header>

        <main style={{ minHeight: "60vh" }}>
          <Switch>
            <Route path="/admin" exact>
              <PendingApplications
                apps={this.state.pendingApps}
                events={this.state.allEvents}
                handlers={{
                  approveClick: this.handleApproveClick.bind(this),
                  rejectClick: this.handleRejectClick.bind(this),
                }}
              />
            </Route>
            <Route path="/admin/users" exact>
              <div className="d-flex flex-column">
                <Button
                  variant="primary"
                  className="align-self-end mb-3"
                  size="md"
                  onClick={() => this.setState({ showNewUserModal: true })}
                >
                  <PlusIcon width={24} height={24} /> Create New User
                </Button>
                <UsersList users={this.state.allUsers} />
              </div>
            </Route>
            <Route path="/admin/users/:id">
              <UserPage
                loggedInUser={this.state.user}
                onUpdate={this.fetchAllUsers.bind(this)}
              />
            </Route>
            <Route path="/admin/events" exact>
              <EventsCalendar
                events={this.state.allEvents}
                onUpdate={this.fetchAllEvents.bind(this)}
              />
            </Route>
            <Route path="/admin/events/:id">
              <EventPage
                users={this.state.allUsers}
                handlers={{
                  approveClick: this.handleApproveClick.bind(this),
                  rejectClick: this.handleRejectClick.bind(this),
                }}
                loggedInUser={this.state.user}
              />
            </Route>
          </Switch>

          <PageModal
            size="sm"
            show={this.state.showNewUserModal}
            onHide={() => this.setState({ showNewUserModal: false })}
            page={{
              title: "Create New User",
              component: (
                <UserForm
                  onUpdate={() => {
                    this.setState({ showNewUserModal: false });
                    this.fetchAllUsers();
                  }}
                />
              ),
            }}
          />
        </main>

        <footer className="text-center">
          <LinkContainer to="/" exact>
            <Button size="md" variant="info" className="mr-2">
              <CalendarIcon width={24} height={24} /> Go to Public Calendar
            </Button>
          </LinkContainer>
          <CurrentTime />
        </footer>
      </Container>
    );
  }
}

export default withRouter(Admin);
