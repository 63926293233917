import React from "react";
import { Link, withRouter } from "react-router-dom";
import Jumbotron from "react-bootstrap/Jumbotron";

function HowToPlay() {
  return (
    <Jumbotron>
      <h1>How to Play</h1>
      <ul style={{ fontSize: "1.1em" }}>
        <li>
          Join our discord (
          <a
            href="https://discord.gg/smight"
            target="_blank"
            rel="noopener noreferrer"
          >
            discord.gg/smight
          </a>
          ) so that we can coordinate with you beforehand and talk to you during
          the actual show.
        </li>
        <li>
          Complete our player setup. We'll provide you with everything you need
          and are around to help if there are any tech issues.
        </li>
        <li>Run a broadcast (500 kbps upload). No audio is required.</li>
        <li>
          Arrive on schedule to play (let us know ASAP if you can't make it).
        </li>
        <li>
          Find a Sunday night that works for you on{" "}
          <Link to="/">the calendar</Link> and sign up! We have a lot of players
          so don't worry about it if your application wasn't approved; just try
          another week. We will try to contact you a week in advance when you
          are selected.
        </li>
        <li>
          You DON'T need a partner. It is certainly preferable, but entirely
          optional.
        </li>
        <li>
          You also don't need to be overly familiar with the show or its rules.
          We will make sure you know what's going on as each event comes up.
          Have fun!
        </li>
      </ul>
    </Jumbotron>
  );
}

export default withRouter(HowToPlay);
