import React from "react";
import { withRouter } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import UserAvatar from "../UserAvatar";

import { ReactComponent as CalendarIcon } from "bootstrap-icons/icons/calendar.svg";
import { ReactComponent as LockIcon } from "bootstrap-icons/icons/lock-fill.svg";
import { ReactComponent as EjectIcon } from "bootstrap-icons/icons/eject.svg";
import { ReactComponent as ArchiveIcon } from "bootstrap-icons/icons/archive.svg";
import { ReactComponent as ListTaskIcon } from "bootstrap-icons/icons/list-task.svg";
import { ReactComponent as PeopleIcon } from "bootstrap-icons/icons/people.svg";

function NavBar(props) {
  const { pendingApps, user } = props;

  return (
    <Navbar bg="dark" variant="dark" className="mb-3" expand="lg">
      <LinkContainer to="/admin" exact>
        <Navbar.Brand href="/admin">
          <LockIcon width={32} height={32} /> Admin
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="admin-top-nav" />
      <Navbar.Collapse id="admin-top-nav">
        <Nav
          variant="pills"
          className="mr-auto"
          activeKey={props.location.pathname}
        >
          <LinkContainer to="/admin" exact>
            <Nav.Link eventKey="/admin">
              <ListTaskIcon width={24} height={24} />
              Pending Apps{" "}
              <Badge variant={pendingApps.length > 0 ? "danger" : "secondary"}>
                {pendingApps.length}
              </Badge>
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to="/admin/events" exact>
            <Nav.Link eventKey="/admin/events">
              <ArchiveIcon width={24} height={24} /> Events
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to="/admin/users" exact>
            <Nav.Link eventKey="/admin/users">
              <PeopleIcon width={24} height={24} /> Users
            </Nav.Link>
          </LinkContainer>
        </Nav>
        <Nav
          className="ml-auto align-items-center"
          activeKey={props.location.pathname}
        >
          <UserAvatar
            imageUrl={user.twitch.profile_image_url}
            alt={"Logged in as " + user.displayName}
            size="md"
          />
          <LinkContainer
            to="/"
            exact
            title="Go to Public Calendar"
            className="ml-2"
          >
            <Button size="md" variant="dark">
              <CalendarIcon width={24} height={24} /> Public
            </Button>
          </LinkContainer>
          <Button
            href={process.env.REACT_APP_LOGOUT_URL}
            variant="dark"
            size="md"
          >
            <EjectIcon width={24} height={24} /> Logout
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default withRouter(NavBar);
